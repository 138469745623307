<template>
  <div class="form-horizontal">
    <p class="required-note">
      <abbr class="required">*</abbr> Required field
    </p>
    <h4>Electricity Rate</h4>
    <select-elec-rate
      ref="elec-rate"/>
    <div class="col-sm-offset-6 col-sm-4 custom-rate-checkbox">
      <input
        v-model="run.site.electric_tariff.custom_electricity_rate"
        class="checkbox-inline"
        type="checkbox"
        name="run[site_attributes][electric_tariff_attributes][custom_electricity_rate]"
        id="run_site_attributes_electric_tariff_attributes_custom_electricity_rate" />
      <label class="custom-electricity-rate-label" for="run_site_attributes_electric_tariff_attributes_custom_electricity_rate">
        Use custom electricity rate
      </label>
      <tooltip
        association_1="site"
        association_2="electric_tariff"
        attribute="custom_electricity_rate" />
    </div>

    <div v-if="run.site.electric_tariff.custom_electricity_rate" class="col-sm-offset-6 col-sm-6 custom-elec-rate-options">
      <input
        v-model="run.site.electric_tariff.custom_rate_type"
        type="radio"
        value="annual"
        name="run[site_attributes][electric_tariff_attributes][custom_rate_type]"
        id="run_site_attributes_electric_tariff_attributes_custom_rate_type_annual" />
      <label class="custom-rate-time-period" for="run_site_attributes_electric_tariff_attributes_custom_rate_type_annual">Annual</label>
      <input
        v-model="run.site.electric_tariff.custom_rate_type"
        type="radio"
        value="monthly"
        name="run[site_attributes][electric_tariff_attributes][custom_rate_type]"
        id="run_site_attributes_electric_tariff_attributes_custom_rate_type_monthly" />
      <label class="custom-rate-time-period" for="run_site_attributes_electric_tariff_attributes_custom_rate_type_monthly">Monthly</label>
      <input
        v-model="run.site.electric_tariff.custom_rate_type"
        type="radio"
        value="detailed"
        name="run[site_attributes][electric_tariff_attributes][custom_rate_type]"
        id="run_site_attributes_electric_tariff_attributes_custom_rate_type_detailed" />
      <label class="custom-rate-time-period" for="run_site_attributes_electric_tariff_attributes_custom_rate_type_detailed">Detailed</label>
      <input
        v-model="run.site.electric_tariff.custom_rate_type"
        type="radio"
        value="urdb_label"
        name="run[site_attributes][electric_tariff_attributes][custom_rate_type]"
        id="run_site_attributes_electric_tariff_attributes_custom_rate_type_urdb_label" />
      <label class="custom-rate-time-period" for="run_site_attributes_electric_tariff_attributes_custom_rate_type_urdb_label">URDB Label</label>
      <input
        v-model="run.site.electric_tariff.custom_rate_type"
        type="radio"
        value="hourly"
        name="run[site_attributes][electric_tariff_attributes][hourly]"
        id="run_site_attributes_electric_tariff_attributes_custom_rate_type_hourly" />
      <label class="custom-rate-time-period" for="run_site_attributes_electric_tariff_attributes_custom_rate_type_hourly">Hourly Upload</label>
    </div>

    <div v-else id="spacer" class="col-sm-offset-6 col-sm-6"></div>

    <div v-if="run.site.electric_tariff.custom_electricity_rate">
      <div v-if="run.site.electric_tariff.custom_rate_type == 'annual'" class="custom-annual-rate">
        <div class="annual-custom-entry col-sm-offset-6 col-sm-4">
          <div class="annual-cost-column">
            <p>Energy Cost ($/kWh)</p>
            <input v-model="run.site.electric_tariff.blended_annual_energy_rate" label="false" class="numeric decimal optional form-control" type="number" step="any" name="run[site_attributes][electric_tariff_attributes][blended_annual_energy_rate]" />
          </div>
          <div class="annual-cost-column">
            <p>Demand Cost ($/kW/month)</p>
            <input v-model="run.site.electric_tariff.blended_annual_demand_rate" label="false" class="numeric decimal optional form-control" type="number" step="any" name="run[site_attributes][electric_tariff_attributes][blended_annual_demand_rate]" />
          </div>
        </div>
      </div>
      <div v-else-if="run.site.electric_tariff.custom_rate_type == 'monthly'" class="custom-monthly-rate">
        <div class="container">
          <div class="row">
            <div class="col-xs-8 col-sm-8" style="text-align: right;">
            </div>
            <div class="col-xs-2 col-sm-2">
              <p>Energy Cost<br>($/kWh)</p>
            </div>
            <div class="col-xs-2 col-sm-2">
              <p>Demand Cost<br>($/kW/month)</p>
            </div>
          </div>
        </div>
        <div v-for="(month, index) in months" class="container monthly-entry-rows">
          <div class="row">
            <div class="col-xs-8 col-sm-8" style="text-align: right;">
              {{ month }}
            </div>
            <div class="col-xs-2 col-sm-2 monthly-entry-row">
              <input v-model="run.site.electric_tariff[`energy_cost_monthly_${index + 1}`]" @paste="pasteInputs('electric_tariff', 'electricity_cost')" class="numeric decimal optional energy-cost-monthly form-control" label="false" :aria-label="`${month} Energy Cost($/kWh)`" type="number" step="any" :name="`run[site_attributes][electric_tariff_attributes][energy_cost_monthly_${index + 1}]`" />
            </div>
            <div class="col-xs-2 col-sm-2 monthly-entry-row">
              <input v-model="run.site.electric_tariff[`demand_cost_monthly_${index + 1}`]" @paste="pasteInputs('electric_tariff', 'demand_cost')" class="numeric decimal optional demand-cost-monthly form-control" label="false" :aria-label="`${month} Demand Cost($/kWh/month)`" type="number" step="any" :name="`run[site_attributes][electric_tariff_attributes][demand_cost_monthly_${index + 1}]`" />
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="!currentUser && run.site.electric_tariff.custom_rate_type == 'detailed'" class="col-sm-offset-6 col-sm-4">
        <p>You must be <a href="/tool/login">logged in</a> to create a Custom Tariff.</p>
      </div>
      <div v-else-if="currentUser && run.site.electric_tariff.custom_rate_type == 'detailed' && customRates.length > 0" class="select-custom-tariff">
        <div class="form-group select required run_custom_tariff_id">
          <div class="col-sm-6 control-label">
            <label class="select required" for="run_custom_tariff_id"><abbr title="Required" class="required">*</abbr> Select custom rate</label>
          </div>
          <div class="col-sm-4">
            <select v-model="run.custom_tariff_id" class="form-control select required" required="required" aria-required="true" name="run[custom_tariff_id]">
              <option value=""></option>
              <option v-for="rate in customRates" :value="rate.id">{{ rate.name }}</option>
            </select>
            <p class="help-block"><a href="/tool/custom_tariffs/new">Create new custom rate</a></p>
          </div>
        </div>
      </div>
      <div v-else-if="currentUser && run.site.electric_tariff.custom_rate_type == 'detailed' && (!customRates || customRates.length == 0)" class="col-sm-offset-6 col-sm-5 select-custom-tariff">
        <p>The link below will take you to a new page to create a new custom rate</p>
        <a href="/tool/custom_tariffs/new" class="btn btn-primary custom-rate-link" data-confirm="This will take you to a new page and you will lose any progress currently made. Are you sure you want to proceed?">Create new custom rate</a>
      </div>
      <div v-else-if="run.site.electric_tariff.custom_rate_type == 'urdb_label'" class="urdb-label">
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='electric_tariff'
          attribute='custom_urdb_label'
          required='true' />
      </div>
      <div v-if="run.site.electric_tariff.custom_rate_type == 'hourly'" class="custom-hourly-rate form-group file optional run_site_electric_tariff_energy_rate_file">
        <div class="hourly-custom-entry col-sm-offset-6 col-sm-4">
          <p style="margin-right: 5px;">Upload the hourly cost of energy ($/kWh)</p>
          <tooltip
            association_1="site"
            association_2="electric_tariff"
            attribute="custom_energy_rate" />
        </div>
        <div class="col-sm-offset-6 col-sm-6" style="margin-bottom: 10px;">
          <input
            class="form-control hidden"
            type="hidden"
            name="run[site_attributes][electric_tariff_attributes][energy_rate_file]"
            id="run_site_attributes_electric_tariff_attributes_energy_rate_file" />
          <input
            @change="parseCSV($event)"
            class="form-control-file file optional energy-rate-file-upload"
            type="file"
            name="run[site_attributes][electric_tariff_attributes][energy_rate_file]" />
          <!-- <p v-if="run.copied && this.run.site.electric_tariff.energy_rate_file_data" class="help-block">Uploaded file: {{ JSON.parse(this.run.site.electric_tariff.energy_rate_file_data).metadata.filename }}</p> -->
          <p v-if="run.copied && this.run.site.electric_tariff.energy_rate_file_name" class="help-block">Uploaded file: {{ this.run.site.electric_tariff.energy_rate_file_name }}</p>
          <p class="help-block">
            <a style="font-size:12px;" href="/tool/energy_rate_template.csv">Sample custom energy rate file</a>
          </p>
        </div>
        <input
          v-model="run.site.electric_tariff.energy_rate_series_kwh"
          class="form-control hidden"
          type="hidden"
          name="run[site_attributes][electric_tariff_attributes][energy_rate_series_kwh]"
          id="run_site_attributes_electric_tariff_attributes_energy_rate_series_kwh" />
      </div>
    </div>
    <div v-if="run.analyze_pv || run.analyze_wind || run.analyze_chp || run.analyze_prime_generator">
      <h4>Compensation for Exported Electricity <tooltip :association_1="'site'" :association_2="'headers'" :attribute="'compensation_for_exports'"></tooltip></h4>
      <horizontal-select-input-nested-2
        association_1='site'
        association_2='electric_tariff'
        attribute='compensation_type' />
      <div>
        <div v-if="run.site.electric_tariff.compensation_type == 'net_metering' || run.site.electric_tariff.compensation_type == 'net_meter_net_bill'">
          <h5>Net Metering Inputs</h5>
          <horizontal-string-input-nested-2
            association_1='site'
            association_2='electric_tariff'
            attribute='net_metering_limit_kw'
            required=true />
          <div class="form-group techs-net-meter">
            <div class="col-sm-6 control-label">
              Technologies that can net meter <tooltip :attribute="'can_net_meter'"></tooltip>
            </div>
            <div class="col-sm-4 tech-options">
              <div v-if="run.analyze_pv" id="pv-net-meter">
                <input
                  type="hidden"
                  name="run[site_attributes][pv_attributes][can_net_meter]"
                  value="0" />
                <input
                  v-model="run.site.pv.can_net_meter"
                  type="checkbox"
                  name="run[site_attributes][pv_attributes][can_net_meter]"
                  value="1"
                  id="run_site_attributes_pv_attributes_can_net_meter" />
                <label for="run_site_attributes_pv_attributes_can_net_meter">PV</label><br />
              </div>
              <div v-if="run.analyze_wind" id="wind-net-meter">
                <input
                  type="hidden"
                  name="run[site_attributes][wind_attributes][can_net_meter]"
                  value="0" />
                <input
                  v-model="run.site.wind.can_net_meter"
                  type="checkbox"
                  name="run[site_attributes][wind_attributes][can_net_meter]"
                  value="1"
                  id="run_site_attributes_wind_attributes_can_net_meter" />
                <label for="run_site_attributes_wind_attributes_can_net_meter">Wind</label><br />
              </div>
              <div v-if="run.analyze_chp && run.site.chp.prime_mover_type != 'steam_turbine'" id="chp-net-meter">
                <input
                  type="hidden"
                  name="run[site_attributes][chp_attributes][can_net_meter]"
                  value="0" />
                <input
                  v-model="run.site.chp.can_net_meter"
                  type="checkbox"
                  name="run[site_attributes][chp_attributes][can_net_meter]"
                  value="1"
                  id="run_site_attributes_chp_attributes_can_net_meter" />
                <label for="run_site_attributes_chp_attributes_can_net_meter">CHP</label>
              </div>
              <div v-if="run.analyze_prime_generator" id="prime-generator-net-meter">
                <input
                  type="hidden"
                  name="run[site_attributes][prime_generator_attributes][can_net_meter]"
                  value="0" />
                <input
                  v-model="run.site.prime_generator.can_net_meter"
                  type="checkbox"
                  name="run[site_attributes][prime_generator_attributes][can_net_meter]"
                  value="1"
                  id="run_site_attributes_prime_generator_attributes_can_net_meter" />
                <label for="run_site_attributes_prime_generator_attributes_can_net_meter">Prime Generator</label>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-6 control-label">
              Maximum ratio of grid exports to grid purchases that can receive net metering compensation
              <tooltip :association_1="'site'" :association_2="'electric_tariff'" :attribute="'max_ratio_of_grid_exports'"></tooltip>
            </label>
            <div class="col-sm-4">
              <p class="form-control-static">100%</p>
            </div>
          </div>
          <horizontal-string-input-nested-2
            association_1='site'
            association_2='electric_tariff'
            attribute='export_rate_beyond_net_metering_limit' />
        </div>
      </div>
      <div>
        <div v-if="run.site.electric_tariff.compensation_type == 'net_billing' || run.site.electric_tariff.compensation_type == 'net_meter_net_bill'">
          <h5>Net Billing Inputs</h5>
          <div>
            <horizontal-string-input-nested-2
              association_1='site'
              association_2='electric_tariff'
              attribute='wholesale_rate'
              required=true />
          </div>
          <div class="col-sm-offset-6 col-sm-6 custom-rate-checkbox">
            <input
              v-model="run.site.electric_tariff.custom_wholesale_rate"
              class="checkbox-inline"
              type="checkbox"
              name="run[site_attributes][electric_tariff_attributes][custom_wholesale_rate]"
              id="run_site_attributes_electric_tariff_attributes_custom_wholesale_rate" />
            <label class="custom-electricity-rate-label" for="run_site_attributes_electric_tariff_attributes_custom_wholesale_rate">
              Net billing rate varies with time?
            </label>
            <tooltip
              association_1="site"
              association_2="electric_tariff"
              attribute="custom_wholesale_rate" />
          </div>
          <!-- create a little space between the custom_wholesale_rate checkbox and the techs that can net bill checkboxes -->
          <div v-if="!run.site.electric_tariff.custom_wholesale_rate"><br><br></div>
          <div v-if="run.site.electric_tariff.custom_wholesale_rate" class="form-group file optional run_site_electric_tariff_wholesale_rate_file">
            <div class="col-sm-offset-6 col-sm-6">
              <input
                class="form-control hidden"
                type="hidden"
                name="run[site_attributes][electric_tariff_attributes][wholesale_rate_file]"
                id="run_site_attributes_electric_tariff_attributes_wholesale_rate_file" />
              <input
                @change="parseCSV($event)"
                class="form-control-file file optional wholesale-rate-file-upload"
                type="file"
                name="run[site_attributes][electric_tariff_attributes][wholesale_rate_file]" />
              <!-- <p v-if="run.copied && this.run.site.electric_tariff.wholesale_rate_file_data" class="help-block">Uploaded file: {{ JSON.parse(this.run.site.electric_tariff.wholesale_rate_file_data).metadata.filename }}</p> -->
              <p v-if="run.copied && this.run.site.electric_tariff.wholesale_rate_file_name" class="help-block">Uploaded file: {{ this.run.site.electric_tariff.wholesale_rate_file_name }}</p>
              <p class="help-block">
                <a style="font-size:12px;" href="/tool/net_billing_rate_template.csv">Sample custom net billing rate file</a>
              </p>
            </div>
          </div>
          <input
            v-model="run.site.electric_tariff.wholesale_rate_series_kw"
            class="form-control hidden"
            type="hidden"
            name="run[site_attributes][electric_tariff_attributes][wholesale_rate_series_kw]"
            id="run_site_attributes_electric_tariff_attributes_wholesale_rate_series_kw" />
          <div class="form-group techs-net-meter">
            <div class="col-sm-6 control-label">
              Technologies that can participate in net billing <tooltip :attribute="'can_wholesale'"></tooltip>
            </div>
            <div class="col-sm-4 tech-options">
              <div v-if="run.analyze_pv" id="pv-can-wholesale">
                <input
                  type="hidden"
                  name="run[site_attributes][pv_attributes][can_wholesale]"
                  value="0"
                  :disabled="!run.analyze_pv" />
                <input
                  v-model="run.site.pv.can_wholesale"
                  type="checkbox"
                  name="run[site_attributes][pv_attributes][can_wholesale]"
                  value="1"
                  id="run_site_attributes_pv_attributes_can_wholesale"
                  :disabled="!run.analyze_pv" />
                <label for="run_site_attributes_pv_attributes_can_wholesale">PV</label><br />
              </div>
              <div v-if="run.analyze_wind" id="wind-can-wholesale">
                <input
                  type="hidden"
                  name="run[site_attributes][wind_attributes][can_wholesale]"
                  value="0" />
                <input
                  v-model="run.site.wind.can_wholesale"
                  type="checkbox"
                  name="run[site_attributes][wind_attributes][can_wholesale]"
                  value="1"
                  id="run_site_attributes_wind_attributes_can_wholesale" />
                <label for="run_site_attributes_wind_attributes_can_wholesale">Wind</label><br />
              </div>
              <div v-if="run.analyze_chp && run.site.chp.prime_mover_type != 'steam_turbine'" id="chp-can-wholesale">
                <input
                  type="hidden"
                  name="run[site_attributes][chp_attributes][can_wholesale]"
                  value="0" />
                <input
                  v-model="run.site.chp.can_wholesale"
                  type="checkbox"
                  name="run[site_attributes][chp_attributes][can_wholesale]"
                  value="1"
                  id="run_site_attributes_chp_attributes_can_wholesale" />
                <label for="run_site_attributes_chp_attributes_can_wholesale">CHP</label>
              </div>
              <div v-if="run.analyze_prime_generator" id="prime-generator-can-wholesale">
                <input
                  type="hidden"
                  name="run[site_attributes][prime_generator_attributes][can_wholesale]"
                  value="0" />
                <input
                  v-model="run.site.prime_generator.can_wholesale"
                  type="checkbox"
                  name="run[site_attributes][prime_generator_attributes][can_wholesale]"
                  value="1"
                  id="run_site_attributes_prime_generator_attributes_can_wholesale" />
                <label for="run_site_attributes_prime_generator_attributes_can_wholesale">Prime Generator</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!(run.analyze_chp || run.analyze_ghp || run.analyze_ashp_space)" class="col-sm-12" id="custom-rate-checkbox" style="margin-bottom:10px;">
      <input
        v-model="run.site.load_profile_boiler.include_site_heating_load"
        class="checkbox-inline"
        type="checkbox"
        name="run[site_attributes][load_profile_boiler_attributes][include_site_heating_load]"
        id="run_site_attributes_load_profile_boiler_attributes_include_site_heating_load" />
      <label class="custom-electricity-rate-label" for="run_site_attributes_load_profile_boiler_attributes_include_site_heating_load">Include the site's heating load for fuel consumption, cost, and emissions accounting</label>
      <tooltip
        :association_1="'site'"
        :attribute="'include_site_heating_load'" />
      <br><br>
    </div>

    <div v-if="run.analyze_chp || run.analyze_prime_generator || run.analyze_ghp || run.analyze_ashp_space || run.site.load_profile_boiler.include_site_heating_load">
      <h4 class="col-sm-8">Fuel Costs</h4>
      <horizontal-select-input-nested-2
        v-if="run.analyze_chp || run.analyze_ghp || run.analyze_ashp_space || run.site.load_profile_boiler.include_site_heating_load"
        association_1='site'
        association_2='boiler'
        attribute='fuel_type'
        v-on:input-change="updateEmissionsFactorLabel(run.site.boiler, 'boiler-emissions-factor-label', 'Boiler')"
      />
      <div v-if="run.analyze_chp || run.analyze_ghp || run.analyze_ashp_space || run.site.load_profile_boiler.include_site_heating_load">
        <div class="row section-actions" style="margin-bottom:0px;">
          <div class="col-sm-6">&nbsp;</div>
          <div class="col-sm-6">
            <input
              type="checkbox"
              v-model="run.site.boiler.monthly_fuel_cost"
              name="run[site_attributes][boiler_attributes][monthly_fuel_cost]"
              id="monthly_boiler_cost" />
            <label style="font-weight: normal" for="monthly_boiler_cost">
              &nbsp;&nbsp;Heating system fuel cost varies by month?
            </label>
          </div>
        </div>
        <monthly-inputs
          v-if="run.site.boiler.monthly_fuel_cost"
          type="boiler-cost"
          v-on:paste-inputs="pasteInputs('boiler', 'boiler-cost')" />
        <horizontal-string-input-nested-2
          v-else
          association_1='site'
          association_2='boiler'
          attribute='fuel_blended_annual_rates_per_mmbtu'
          required=true />
      </div>
      <div v-if="run.analyze_chp && run.site.chp.prime_mover_type != 'steam_turbine'">
        <horizontal-select-input-nested-2
          v-if="run.analyze_chp && run.site.chp.prime_mover_type != 'steam_turbine'"
          association_1='site'
          association_2='chp'
          attribute='fuel_type'
          v-on:input-change="updateEmissionsFactorLabel(run.site.chp, 'chp-emissions-factor-label', 'CHP')" />
        <div class="row section-actions" style="margin-bottom:0px;">
          <div class="col-sm-6">&nbsp;</div>
          <div class="col-sm-4">
            <input
              type="checkbox"
              v-model="run.site.chp.monthly_fuel_cost"
              name="run[site_attributes][chp_attributes][monthly_fuel_cost]"
              id="monthly_chp_cost" />
            <label style="font-weight: normal" for="monthly_chp_cost">
              &nbsp;&nbsp;CHP fuel cost varies by month?
            </label>
          </div>
        </div>
        <monthly-inputs
          v-if="run.site.chp.monthly_fuel_cost"
          type="chp-fuel-cost"
          v-on:paste-inputs="pasteInputs('chp', 'chp-fuel-cost')" />
        <horizontal-string-input-nested-2
          v-else
          association_1='site'
          association_2='chp'
          attribute='fuel_blended_annual_rates_per_mmbtu'
          required=true />
      </div>
      <div v-if="run.analyze_prime_generator">
        <horizontal-select-input-nested-2
          v-if="run.analyze_prime_generator"
          association_1='site'
          association_2='prime_generator'
          attribute='fuel_type'
          v-on:input-change="updateEmissionsFactorLabel(run.site.prime_generator, 'prime-gen-emissions-factor-label', 'Prime Generator')" />
        <div class="row section-actions" style="margin-bottom:0px;">
          <div class="col-sm-6">&nbsp;</div>
          <div class="col-sm-6">
            <input
              type="checkbox"
              v-model="run.site.prime_generator.monthly_fuel_cost"
              name="run[site_attributes][prime_generator_attributes][monthly_fuel_cost]"
              id="monthly_prime_generator_cost" />
            <label style="font-weight: normal" for="monthly_prime_generator_cost">
              &nbsp;&nbsp;Prime Generator fuel cost varies by month?
            </label>
          </div>
        </div>
        <monthly-inputs
          v-if="run.site.prime_generator.monthly_fuel_cost"
          type="prime-generator-fuel-cost"
          v-on:paste-inputs="pasteInputs('prime_generator', 'prime-generator-fuel-cost')" />
        <horizontal-string-input-nested-2
          v-else
          association_1='site'
          association_2='prime_generator'
          attribute='fuel_blended_annual_rates_per_mmbtu'
          required=true />
      </div>
    </div>

    <div class="row section-actions" v-if="run.analyze_chp || run.analyze_prime_generator">
      <div class="col-sm-6 col-sm-offset-6">
        <div class="toggle-more-inputs pull-left">
          <a @click="toggleAdvancedInputs();">
            <span v-if="displayAdvancedInputs">
              <font-awesome-icon icon='minus-square' />
              Show fewer inputs
            </span>
            <span v-else><font-awesome-icon icon='plus-square' /> Advanced inputs</span>
          </a>
        </div>
        <div v-if="!displayAdvancedInputs" class="reset-inputs pull-right">
          <a @click="resetInputs()">
            <i class="fas fa-sync-alt" aria-hidden="true"></i>Reset to default values
          </a>
        </div>
      </div>
    </div>
    
    <div v-show="displayAdvancedInputs && (run.analyze_chp || run.analyze_prime_generator)">
      <h4>Electricity Standby Charges</h4>
      <div v-if="run.analyze_chp && run.site.chp.prime_mover_type != 'steam_turbine'">
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='electric_tariff'
          attribute='chp_standby_rate_per_kw_per_month' />
      </div>
      <div v-if="run.analyze_prime_generator">
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='electric_tariff'
          attribute='prime_generator_standby_rate_per_kw_per_month' />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import HorizontalStringInputNested2 from './inputs/HorizontalStringInputNested2';
  import HorizontalStringInputNested3 from './inputs/HorizontalStringInputNested3';
  import HorizontalSelectInputNested2 from './inputs/HorizontalSelectInputNested2';
  import SelectElecRate from './inputs/SelectElecRate';
  import MonthlyInputs from './inputs/MonthlyInputs';
  import Tooltip from './Tooltip';

  export default {
    components: {
      HorizontalStringInputNested2,
      HorizontalStringInputNested3,
      HorizontalSelectInputNested2,
      SelectElecRate,
      Tooltip,
      MonthlyInputs
    },

    data: function() {
      return {
        displayAdvancedInputs: false
      }
    },

    computed: {
      ...mapGetters([
        'currentUser',
        'customRates',
        'months',
        'placeholders',
        'run',
        'placeholders',
        'annualMmbtuForRatio',
        'annualTonHourForRatio',
        'dhwAnnualMMbtuForRatio',
      ]),
    },

    methods: {
      toggleAdvancedInputs: function() {
        this.displayAdvancedInputs = !this.displayAdvancedInputs;
      },
      updateStoreNested1: function(association_1, attribute) {
        var payload = {
          association_1: association_1,
          attribute: attribute,
          value: this.run[association_1][attribute],
        }
        this.$store.commit('updateValueNested1', payload);
      },
      setupLocationAutocomplete: function() {
        var self = this;
        var locationInput = document.getElementById('run_site_attributes_address');
        if(locationInput) {
          var autocomplete = new google.maps.places.Autocomplete(locationInput, {
            types: ['geocode'],
          });
          autocomplete.addListener('place_changed', function selectAddress() {
            var place = autocomplete.getPlace();
            if(!place.geometry) {
              return;
            }
            self.$store.commit('updateValueNested1', {
              association_1: 'site',
              attribute: 'address',
              value: place.formatted_address
            })
            self.$store.commit('updateValueNested1', {
              association_1: 'site',
              attribute: 'latitude',
              value: place.geometry.location.lat()
            })
            self.$store.commit('updateValueNested1', {
              association_1: 'site',
              attribute: 'longitude',
              value: place.geometry.location.lng()
            })

            // TODO: fix weird Vue reactivity issue
            // for w/e reason, without the value setting below, Vue will update the
            // data model, i.e. `run.site.latitude` but not the input UNTIL some JS has
            // been performed in this component (for instance clicking the advanced inputs).
            // This will THEN update the input to be consistent with the data model
            document.getElementById('run_site_attributes_latitude').value = place.geometry.location.lat();
            document.getElementById('run_site_attributes_longitude').value = place.geometry.location.lng();

            self.$emit('update-emissions-defaults-ghp-conductivity')
            self.$emit('update-ghp-defaults');

            if(self.run.grid) {
              self.$refs['elec-rate'].fetchUtilityRates();
            }
          });

          // When "enter" is hit and the autocomplete results are up, only select the
          // item (don't submit the form).
          google.maps.event.addDomListener(locationInput, 'keydown', function(event) {
            if(event.keyCode === 13 && $('.pac-container:visible').length) {
              event.preventDefault();
            }
          });
        }
      },
      updateEmissionsFactorLabel: function(technology, id, label) {
        if (technology.fuel_type === "diesel_oil" || technology.fuel_type === "propane") {
          $('#' + id).text(label + ' fuel emissions factor (lb /gal)');
        } else {
          $('#' + id).text(label + ' fuel emissions factor (lb /MMBtu)');
        }
      },
      parseCSV: function(event) {
        var file = event.target.files[0];

        if(file && file.name.split(".")[1] != "csv") {
          bootbox.alert("Uploaded file " + file.name + " must be a .csv");
          if (event.target.classList[3] == 'wholesale-rate-file-upload') {
            this.run.site.electric_tariff.wholesale_rate_series_kw = [];
          } else if (event.target.classList[3] == 'energy-rate-file-upload') {
            this.run.site.electric_tariff.energy_rate_series_kwh = [];
          }
          event.target.value = "";

          return false;
        }

        if(file) {
          var self = this;
          Papa.parse(file, {
            delimiter: ",",
            complete: function(results) {
              try {
                if(results.errors && results.errors.length > 0) {
                  if (event.target.classList[3] == 'wholesale-rate-file-upload') {
                    this.run.site.electric_tariff.wholesale_rate_series_kw = [];
                  } else if (event.target.classList[3] == 'energy-rate-file-upload') {
                    this.run.site.electric_tariff.energy_rate_series_kwh = [];
                  }
                  event.target.value = "";
                  bootbox.alert('The rate generation profile could not be parsed. File must be a CSV.<br><br>Error (row ' + results.errors[0].row + '): ' + results.errors[0].message);

                  return false;
                }

                var rows = results.data;
                var values = [];
                var hasColumns = false;
                for(var i = 0, len = rows.length; i < len; i++) {
                  var row = rows[i];

                  // Detect header columns.
                  if(i < 3) {
                    if(row[0] && typeof row[0] === 'string') {
                      var value = row[0].toLowerCase();

                      // Detect when the CSV has multiple columns. Otherwise assume
                      // the CSV is just straight list of values.
                      if(value.indexOf('hour') !== -1) {
                        hasColumns = true;
                      }

                      // Skip header columns.
                      if(value.indexOf('hour') !== -1 || value.indexOf('directions') !== -1) {
                        continue;
                      }
                    }
                  }

                  // Skip empty rows.
                  if(row.join('') === '') {
                    continue;
                  }

                  // Ensure the values are only numbers.
                  var valueIndex = (hasColumns) ? 1 : 0;
                  var value = parseFloat(row[valueIndex]);
                  if(isNaN(value)) {
                    if (event.target.classList[3] == 'wholesale-rate-file-upload') {
                      self.run.site.electric_tariff.wholesale_rate_series_kw = [];
                    } else if (event.target.classList[3] == 'energy-rate-file-upload') {
                      self.run.site.electric_tariff.energy_rate_series_kwh = [];
                    }
                    event.target.value = "";
                    bootbox.alert('The rate generation profile must contain only numeric values. Could not parse value at row ' + (i + 1) + ': "' + row[valueIndex] + '"');

                    return false;
                  }
                  values.push(value);
                }

                // Validate the file contains the expected number of value rows.
                var valuesCount = values.length;
                if(valuesCount !== 8760 && valuesCount !== 17520 && valuesCount !== 35040) {
                  if (event.target.classList[3] == 'wholesale-rate-file-upload') {
                    self.run.site.electric_tariff.wholesale_rate_series_kw = [];
                  } else if (event.target.classList[3] == 'energy-rate-file-upload') {
                    self.run.site.electric_tariff.energy_rate_series_kwh = [];
                  }
                  event.target.value = "";
                  bootbox.alert('The rate generation profile must contain either 8,760 values (hourly data), 17,520 values (30-minute data), or 35,040 values (15-minute data) of numeric values.')

                  return false;
                }
                if (event.target.classList[3] == 'wholesale-rate-file-upload') {
                  var payload = {
                    association_1: 'site',
                    association_2: 'electric_tariff',
                    attribute: 'wholesale_rate_series_kw',
                    value: JSON.stringify(values),
                  }
                  self.$store.commit('updateValueNested2', payload);
                  self.$store.commit('updateValueNested2', {association_1: 'site', association_2: 'electric_tariff', attribute: 'wholesale_rate_file_name', value: file.name});
                } else if (event.target.classList[3] == 'energy-rate-file-upload') {
                  var payload = {
                    association_1: 'site',
                    association_2: 'electric_tariff',
                    attribute: 'energy_rate_series_kwh',
                    value: JSON.stringify(values),
                  }
                  self.$store.commit('updateValueNested2', payload);
                  self.$store.commit('updateValueNested2', {association_1: 'site', association_2: 'electric_tariff', attribute: 'energy_rate_file_name', value: file.name});
                }
              } finally {}
            }
          });
        }
      },
      pasteInputs: function(technology, type) {
        event.preventDefault();
        var self = this;
        var clip = event.clipboardData.getData('text');

        if (clip.includes('\r\n')) {
          var clipArray = clip.split('\r\n');
          if (clipArray.length > 12) {
            // remove any values at the end of the array if they exceed the 12 month count so the first 12 paste to the correct months
            clipArray.splice(12, clipArray.length - 12);
          }
          // remove any commas within the numbers if they exist
          clipArray.forEach(function(item, i) {
            clipArray[i] = item.split(',').join('');
          })

          var month = parseInt(event.target.name.replaceAll(']', '').split('[')[3].split('_')[3]);
          var attributeNameArray = event.target.name.replaceAll(']', '').split('[')[3].split('_');
          attributeNameArray.splice(-1);
          var attrBaseName = attributeNameArray.join('_');
        } else {
          // remove any commas if they exist for single value
          var clipValue = parseFloat(clip.split(',').join(''));
          var attributeName = event.target.name.replaceAll(']', '').split('[')[3];
        }
        if (clipArray) {
          // for multi-input paste
          clipArray.forEach(function(item, i) {
            // Ensure the values are only numbers.
            if (isNaN(parseFloat(item))) {
              bootbox.alert('The monthly input must contain only numeric values. Could not parse value "' + item + '"');
              return false;
            } else {
              self.$store.commit('updateValueNested2', {
                association_1: 'site',
                association_2: technology,
                attribute: attrBaseName + `_${i + month}`,
                value: parseFloat(item)
              });
            }
          });
        } else {
          self.$store.commit('updateValueNested2', {
            association_1: 'site',
            association_2: technology,
            attribute: attributeName,
            value: clipValue
          });
        }
      },
      resetInputs: function() {
        if(confirm("Are you sure you want to reset the inputs in the Site and Utility section to their default values?")) {
          var self = this;
          // standard (non chp or ghp) site inputs
          if(this.run.site.electric_tariff.urdb_label) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'electric_tariff', attribute: 'urdb_label', value: '' });
          if(this.run.site.electric_tariff.custom_electricity_rate) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'electric_tariff', attribute: 'custom_electricity_rate', value: '' });
          if(this.run.site.electric_tariff.custom_rate_type != 'annual') this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'electric_tariff', attribute: 'custom_rate_type', value: 'annual' });
          if(this.run.site.electric_tariff.blended_annual_energy_rate) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'electric_tariff', attribute: 'blended_annual_energy_rate', value: '' });
          if(this.run.site.electric_tariff.blended_annual_demand_rate) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'electric_tariff', attribute: 'blended_annual_demand_rate', value: '' });

          // reset monthly inputs
          for(var i = 1; i <= 12; i++) {
            var energy_cost_attr = `energy_cost_monthly_${i}`;
            var demand_cost_attr = `demand_cost_monthly_${i}`;
            var boiler_cost_attr = `fuel_monthly_rate_${i}`;
            var chp_cost_attr = `fuel_monthly_rate_${i}`;
            var prime_generator_cost_attr = `fuel_monthly_rate_${i}`;

            if(this.run.site.electric_tariff[energy_cost_attr]) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'electric_tariff', attribute: energy_cost_attr, value: '' });
            if(this.run.site.electric_tariff[demand_cost_attr]) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'electric_tariff', attribute: demand_cost_attr, value: '' });
            if(this.run.site.boiler[boiler_cost_attr]) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'boiler', attribute: boiler_cost_attr, value: '' });
            if(this.run.site.chp[chp_cost_attr]) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'chp', attribute: chp_cost_attr, value: '' });
            if(this.run.site.prime_generator[prime_generator_cost_attr]) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'prime_generator', attribute: prime_generator_cost_attr, value: '' });
          }

          if(this.run.site.electric_tariff.custom_urdb_label) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'electric_tariff', attribute: 'custom_urdb_label', value: '' });
          if(this.run.site.electric_tariff.compensation_type) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'electric_tariff', attribute: 'compensation_type', value: 'no_compensation' });
          if(this.run.site.electric_tariff.export_rate_beyond_net_metering_limit) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'electric_tariff', attribute: 'export_rate_beyond_net_metering_limit', value: '' });
          if(this.run.site.electric_tariff.net_metering_limit_kw) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'electric_tariff', attribute: 'net_metering_limit_kw', value: '' });
          if(this.run.site.electric_tariff.wholesale_rate) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'electric_tariff', attribute: 'wholesale_rate', value: '' });
          if(this.run.site.electric_tariff.custom_wholesale_rate) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'electric_tariff', attribute: 'custom_wholesale_rate', value: false });
          if(this.run.site.electric_tariff.wholesale_rate_file_data) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'electric_tariff', attribute: 'wholesale_rate_file_data', value: '' });
          if(this.run.site.electric_tariff.wholesale_rate_file_name) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'electric_tariff', attribute: 'wholesale_rate_file_name', value: '' });

          // chp inputs
          if(this.run.site.boiler.monthly_fuel_cost) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'boiler', attribute: 'monthly_fuel_cost', value: false });
          if(this.run.site.chp.monthly_fuel_cost) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'chp', attribute: 'monthly_fuel_cost', value: false });
          if(this.run.site.boiler.fuel_blended_annual_rates_per_mmbtu) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'boiler', attribute: 'fuel_blended_annual_rates_per_mmbtu', value: '' });
          if(this.run.site.chp.fuel_blended_annual_rates_per_mmbtu) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'chp', attribute: 'fuel_blended_annual_rates_per_mmbtu', value: '' });
          if(this.run.site.electric_tariff.chp_standby_rate_per_kw_per_month) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'electric_tariff', attribute: 'chp_standby_rate_per_kw_per_month', value: '' });
          if(this.run.site.boiler.fuel_type != 'natural_gas') this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'boiler', attribute: 'fuel_type', value: 'natural_gas' });

          // prime generator inputs
          if(this.run.site.prime_generator.monthly_fuel_cost) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'prime_generator', attribute: 'monthly_fuel_cost', value: false });
          if(this.run.site.prime_generator.fuel_blended_annual_rates_per_mmbtu) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'prime_generator', attribute: 'fuel_blended_annual_rates_per_mmbtu', value: '' });
          if(this.run.site.electric_tariff.prime_generator_standby_rate_per_kw_per_month) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'electric_tariff', attribute: 'prime_generator_standby_rate_per_kw_per_month', value: '' });
          if(this.run.site.prime_generator.fuel_type != 'natural_gas') this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'prime_generator', attribute: 'fuel_type', value: 'natural_gas' });

          // net meter inputs
          if(!this.run.site.pv.can_net_meter) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'pv', attribute: 'can_net_meter', value: true });
          if(!this.run.site.wind.can_net_meter) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'wind', attribute: 'can_net_meter', value: true });
          if(this.run.site.chp.can_net_meter) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'chp', attribute: 'can_net_meter', value: false });
          if(this.run.site.prime_generator.can_net_meter) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'prime_generator', attribute: 'can_net_meter', value: false });

          // can wholesale inputs
          if(!this.run.site.pv.can_wholesale) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'pv', attribute: 'can_wholesale', value: true });
          if(!this.run.site.wind.can_wholesale) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'wind', attribute: 'can_wholesale', value: true });
          if(this.run.site.chp.can_wholesale) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'chp', attribute: 'can_wholesale', value: false });
          if(this.run.site.prime_generator.can_wholesale) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'prime_generator', attribute: 'can_wholesale', value: false });

          window.scrollTo(0, document.getElementById('site_heading').offsetTop);
        }
      },
    },

    watch: {
      'run.site.electric_tariff.custom_electricity_rate': function() {
        if(!this.run.site.electric_tariff.custom_electricity_rate) {
          this.$store.commit('updateValueNested2', { association_1: 'site', association_2: 'electric_tariff', attribute: 'custom_rate_type', value: '' });
        } else {
          if(!this.run.site.electric_tariff.custom_rate_type) {
            this.$store.commit('updateValueNested2', { association_1: 'site', association_2: 'electric_tariff', attribute: 'custom_rate_type', value: 'annual' });
          }
        }
      },
    },

    mounted: function() {
      this.setupLocationAutocomplete();

      // for copied runs
      if(this.run.grid && this.run.site.address) {
        this.$refs['elec-rate'].fetchUtilityRates();
      }
      // for old runs before compensation_type was added
      if (!this.run.site.electric_tariff.compensation_type && !this.run.site.electric_tariff.wholesale_rate && !this.run.site.electric_tariff.net_metering_limit_kw) {
        this.$store.commit('updateValueNested2', { association_1: 'site', association_2: 'electric_tariff', attribute: 'compensation_type', value: "no_compensation" });
      } else if (!this.run.site.electric_tariff.compensation_type && this.run.site.electric_tariff.wholesale_rate) {
        this.$store.commit('updateValueNested2', { association_1: 'site', association_2: 'electric_tariff', attribute: 'compensation_type', value: "net_billing" });
      } else if (!this.run.site.electric_tariff.compensation_type && this.run.site.electric_tariff.net_metering_limit_kw) {
        this.$store.commit('updateValueNested2', { association_1: 'site', association_2: 'electric_tariff', attribute: 'compensation_type', value: "net_metering" });
      } else if (!this.run.site.electric_tariff.compensation_type && this.run.site.electric_tariff.wholesale_rate && this.run.site.electric_tariff.net_metering_limit_kw) {
        this.$store.commit('updateValueNested2', { association_1: 'site', association_2: 'electric_tariff', attribute: 'compensation_type', value: "net_meter_net_bill" });
      }
      if(this.run.site.electric_tariff.wholesale_rate_file_data) {
        document.getElementById("run_site_attributes_electric_tariff_attributes_wholesale_rate_file").value = this.run.site.electric_tariff.wholesale_rate_file_data;
      }
      if (this.run.site.electric_tariff.custom_rate_type == "urdb_label" && this.run.site.electric_tariff.urdb_label) {
        // need to update the input value and the vue store apparently.
        // if you just do one or the other the input value/store value doesn't get populated correctly on copy.
        document.getElementById("run_site_attributes_electric_tariff_attributes_custom_urdb_label").value = this.run.site.electric_tariff.urdb_label
        this.$store.commit('updateValueNested2', { association_1: 'site', association_2: 'electric_tariff', attribute: 'custom_urdb_label', value: this.run.site.electric_tariff.urdb_label });
      }
      // Set the below values to null on copy if no custom_wholesale_rate. This is for the edge case when a user might upload
      // a custom wholesale rate and then changes their mind and unchecks the varies with time box, then clicks get results.
      if (!this.run.site.electric_tariff.custom_wholesale_rate) {
        this.$store.commit('updateValueNested2', { association_1: 'site', association_2: 'electric_tariff', attribute: 'wholesale_rate_file_name', value: null });
        this.$store.commit('updateValueNested2', { association_1: 'site', association_2: 'electric_tariff', attribute: 'wholesale_rate_series_kw', value: null });
      }
    }
  }
</script>