<template>
  <div class="form-horizontal">
    <p class="required-note"><abbr class="required">*</abbr> Required field</p>

    <h4>
      <abbr class="required">*</abbr> Critical electric load
      <tooltip
        :association_1="'site'"
        :association_2="'load_profile'"
        :attribute="'critical_electric_load'" />
    </h4>
    <p>How would you like to enter the critical energy load profile?</p>

    <input
      v-model="run.site.load_profile.critical_load_type"
      type="hidden" name="run[site_attributes][load_profile_attributes][critical_load_type]" />

    <ul class="nav nav-tabs">
      <li class="active"><a data-toggle="tab" href="#percent" @click="updateCriticalLoadType($event)"><i class="fas fa-percent" aria-hidden="true"></i> Percent</a></li>
      <li><a data-toggle="tab" href="#upload" @click="updateCriticalLoadType($event)"><i class="fas fa-upload" aria-hidden="true"></i> Upload</a></li>
      <li><a data-toggle="tab" href="#custom_build" @click="updateCriticalLoadType($event)"><i class="fas fa-puzzle-piece" aria-hidden="true"></i> Build</a></li>
    </ul>
    <div class="tab-content">
      <div v-if="run.site.load_profile.critical_load_type == 'percent'" class="tab-pane in active" id="percent">
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='load_profile'
          attribute='critical_load_fraction'
          v-on:input-change="displayCriticalLPLoading()" />
      </div>
      <div v-else-if="!currentUser && run.site.load_profile.critical_load_type == 'upload'" class="tab-pane" id="upload">
        <input v-model="run.site.load_profile.critical_loads_kw" class="form-control hidden" type="hidden" name="run[site_attributes][load_profile_attributes][critical_uploaded_loads_kw]" />

        <div class="form-group file required run_site_load_profile_critical_load_file">
          <div class="col-sm-6 control-label">
            <label class="file required" for="run_site_attributes_load_profile_attributes_critical_load_file">
            <abbr title="Required" class="required">*</abbr> Custom load profile</label>
          </div>
          <div class="col-sm-4">
            <input class="form-control hidden" type="hidden" name="run[site_attributes][load_profile_attributes][critical_load_file]" id="run_site_attributes_load_profile_attributes_critical_load_file" />
            <input @change="parseCSV($event)" class="form-control-file file required load-profile-file-upload" required="required" aria-required="true" type="file" name="run[site_attributes][load_profile_attributes][critical_load_file]" />
            <!-- <p v-if="run.copied && this.run.site.load_profile.critical_load_file_data" class="help-block">Uploaded file: {{ JSON.parse(this.run.site.load_profile.critical_load_file_data).metadata.filename }}</p> -->
            <p v-if="run.copied && this.run.site.load_profile.critical_load_file_name" class="help-block">Uploaded file: {{ this.run.site.load_profile.critical_load_file_name }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-offset-6 col-sm-6">
            <a style="font-size:12px;" href="load_profile_template.csv">Sample custom load profile</a>
          </div>
        </div>
      </div>
      <div v-else-if="currentUser && run.site.load_profile.critical_load_type == 'upload'" class="tab-pane" id="upload">
        <div class="form-group select required">
          <div class="col-sm-6 control-label">
            <label class="select required" for="run_site_attributes_load_profile_attributes_critical_load_profile_id">
              <abbr title="Required" class="required">*</abbr> Custom load profile
            </label>
            <tooltip :association_1="'site'" :association_2="'load_profile'" :attribute="'typical_load_file_data'"></tooltip>
          </div>
          <div class="col-sm-4">
            <select
              v-model="run.site.load_profile.critical_load_profile_id"
              @change="updateCriticalLoadProfile()"
              class="form-control select required"
              required="required"
              aria-required="true"
              name="run[site_attributes][load_profile_attributes][critical_load_profile_id]">
              <option v-for="loadProfile in criticalLoadProfiles" :value="loadProfile.id">
                {{ getCriticalLoadProfileName(loadProfile) }}
              </option>
            </select>
            <p class="help-block">
              <a style="font-size:12px;" href="/tool/load_profile_template.csv">Sample custom load profile</a>
            </p>
          </div>
        </div>
        <input
          v-model="run.site.load_profile.critical_loads_kw"
          class="form-control hidden"
          type="hidden"
          name="run[site_attributes][load_profile_attributes][critical_uploaded_loads_kw]" />
        <div class="row">
          <div class="col-sm-offset-6 col-sm-6">
            <a @click="displayCriticalLoadProfileForm()" class="btn btn-primary">Upload critical load profile</a>
          </div>
        </div>
        <div class="row col-sm-offset-6" style="padding-left:15px;margin-top:15px;">
          <p>Upload and manage <a href="/tool/critical_load_profiles">your critical load profiles</a></p>
        </div>
      </div>
      <div v-else-if="!currentUser && run.site.load_profile.critical_load_type == 'custom_build'" class="tab-pane fade" id="custom_build">
        <div class="row">
          <div class="col-sm-offset-2 col-sm-10 critical-build-anon-text">
            <p>The Critical Load Builder allows you to build, edit, and manage your load profiles.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-offset-2 col-sm-10 critical-build-anon-text">
            <p>You must be logged in to build a critical load profile. <a href="/tool/login">Login or register</a></p>
          </div>
        </div>
      </div>
      <div v-else-if="currentUser && run.site.load_profile.critical_load_type == 'custom_build'" class="tab-pane fade" id="custom_build">
        <div class="form-group select required run_critical_build_id">
          <div class="col-sm-6 control-label">
            <label class="select required" for="run_critical_build_id">
            <abbr title="Required" class="required">*</abbr> Critical load profile</label>
            <tooltip :association_1="'site'" :association_2="'load_profile'" :attribute="'critical_build_id'"></tooltip>
          </div>
          <div class="col-sm-4">
            <select
              v-model="run.critical_build_id"
              @change="updateCriticalBuildLoadProfile()"
              class="form-control select required"
              required="required"
              aria-required="true"
              name="run[critical_build_id]">
              <option value></option>
              <option v-if="criticalBuilds.length > 0" v-for="criticalBuild in criticalBuilds" @change="updateCriticalLoadsKw()" :value="criticalBuild.id">
                {{ criticalBuild.name }}
              </option>
            </select>
            <p class="help-block">Select the critical load profile to be used for the optimization from the menu above.</p>
          </div>
        </div>
        <input v-model="run.site.load_profile.critical_loads_kw" class="form-control hidden" type="hidden" name="run[site_attributes][load_profile_attributes][critical_build_loads_kw]" />

        <div class="row">
          <div class="col-sm-offset-6 col-sm-6">
            <a @click="displayCriticalBuildForm()" class="btn btn-primary">Build New Critical Load Profile</a>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-offset-6 col-sm-6 critical-builds-index-link">
            <p>Build, copy, and manage <a data-confirm="Are you sure you want to leave the form? Any progress will be lost." href="/tool/critical_builds">your critical load profiles</a>.</p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="run.site.load_profile.critical_load_type == 'percent'" class="row">
      <div class="col-sm-6">
        <div>
          <a v-if="!criticalLoadProfileLoading" @click="downloadLoadProfile('criticalPercentLoadProfile')" class="load-profile-download-link load-profile-action no-visit-color">
            <i class="fas fa-download" aria-hidden="true"></i>Download critical load profile
          </a>
          <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
        </div>
      </div>
      <div class="col-sm-6 text-right">
        <a v-if="!criticalLoadProfileLoading" @click="displayCriticalPercentLoadProfile()" class="load-profile-chart-link load-profile-action no-visit-color">
          <i class="fas fa-chart-area" aria-hidden="true"></i>Chart critical load data
        </a>
        <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
      </div>
    </div>
    <div v-else-if="run.site.load_profile.critical_load_type == 'upload'" >
      <div class="col-sm-6 col-sm-offset-6 text-right">
        <a v-if="!criticalLoadProfileLoading" @click="displayCriticalUploadedLoadProfile()" class="load-profile-chart-link load-profile-action no-visit-color">
          <i class="fas fa-chart-area" aria-hidden="true"></i>Chart critical load data
        </a>
        <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
      </div>
    </div>
    <div v-else-if="run.site.load_profile.critical_load_type == 'custom_build'" class="row">
      <div class="col-sm-6">
        <div>
          <a v-if="!criticalLoadProfileLoading" @click="downloadLoadProfile('criticalBuildLoadProfile')" class="load-profile-download-link load-profile-action no-visit-color">
            <i class="fas fa-download" aria-hidden="true"></i>Download critical load profile
          </a>
          <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
        </div>
      </div>
      <div class="col-sm-6 text-right">
        <a v-if="!criticalLoadProfileLoading" @click="displayCriticalBuildLoadProfile()" class="load-profile-chart-link load-profile-action no-visit-color">
          <i class="fas fa-chart-area" aria-hidden="true"></i>Chart critical load data
        </a>
        <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
      </div>
    </div>

    <transition name="fade">
      <load-profile-modal
        v-if="showCriticalPercentLoadProfile"
        v-on:start-outage-on-peak="startOutageOnPeak"
        :loadType="'criticalPercentLoadProfile'" />
    </transition>
    <transition name="fade">
      <load-profile-modal
        v-if="showCriticalUploadedLoadProfile"
        v-on:start-outage-on-peak="startOutageOnPeak"
        :loadType="'criticalUploadedLoadProfile'" />
    </transition>
    <transition name="fade">
      <load-profile-modal
        v-if="showCriticalBuildLoadProfile"
        v-on:start-outage-on-peak="startOutageOnPeak"
        :loadType="'criticalBuildLoadProfile'" />
    </transition>

    <transition name="fade">
      <critical-load-profile-form
        v-if="showCriticalLoadProfileForm"
        v-on:update-selected-critical-load-profile="updateSelectedCriticalLoadProfile" />
    </transition>
    <transition name="fade">
      <new-critical-build-modal
        v-if="showCriticalBuildForm"
        v-on:update-selected-critical-build="updateSelectedCriticalBuild" />
    </transition>

    <h4><abbr title="Required" class="required">*</abbr> Outage Information</h4>
    <div class="form-horizontal">
      <horizontal-string-input-nested-2
        association_1='site'
        association_2='load_profile'
        required=true
        attribute='outage_duration'
        v-on:input-change="getDefaultOutageDateHour()" />

      <horizontal-select-input-nested-2
        association_1='site'
        association_2='load_profile'
        required=true
        attribute='number_of_outages'
        v-on:input-change="getDefaultOutageDateHour()" />

      <div v-if="run.site.load_profile.number_of_outages == 1">
        <div class="form-group run_site_load_profile_outage_start_date" style="margin-bottom: -14px">
          <div class="col-sm-6 control-label">
            <label class="string">
              <abbr title="Required" class="required">*</abbr>
              Outage start date<tooltip :association_1="'site'" :association_2="'load_profile'" :attribute="'outage_start_date'"></tooltip>
            </label>
          </div>
          <div class="col-sm-4">
            <div class="input-group">
              <div id="date-picker" class="has-feedback has-clear">
                <flat-pickr
                  v-model="run.site.load_profile.outage_start_date"
                  @blur="validateOutageDate()"
                  ref="datePicker"
                  name="run[site_attributes][load_profile_attributes][outage_start_date]"
                  id="run_site_attributes_load_profile_attributes_outage_start_date"
                  :config="flatPickrConfig"/>
              </div>
              <span @click="$refs.datePicker.fp.open()" class="input-group-addon" data-toggle>
                <span class="glyphicon glyphicon-calendar" aria-hidden="true">
                </span>
              </span>
            </div>
            <span v-if="validateOutageDateError" class="help-block">
              {{ validateOutageDateErrorMessage }}
            </span>
          </div>
          <div class="col-sm-2" style="padding-left: 0px">
            <div class="form-control-static" style="padding-top: 14px; padding-bottom: 0px">
              <a @click="displayCriticalLoadWithOutageButton($event)" href="#" class="hint-link no-visit-color" style="font-size: 12px;">
                Autoselect using critical load profile
                <tooltip :association_1="'site'" :association_2="'load_profile'" :attribute="'autoselect_from_critical_load'"></tooltip>
              </a>
            </div>
          </div>
        </div>

        <horizontal-select-input-nested-2
          association_1='site'
          association_2='load_profile'
          attribute='outage_start_hour'
          required=true />
      </div>
      <div v-else-if="run.site.load_profile.number_of_outages >= 2 && run.site.load_profile.number_of_outages <= 4" style="margin-top: 0px">
        <multiple-outages-inputs
          number_of_outages='run.site.load_profile.number_of_outages' 
          v-on:update-multiple-outages-defaults="getDefaultOutageDateHour()" />
      </div>
    </div>

    <div class="reset-inputs pull-right">
      <a @click="resetInputs()">
        <i class="fas fa-sync-alt" aria-hidden="true"></i>Reset to default values
      </a>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import HorizontalStringInputNested2 from './inputs/HorizontalStringInputNested2';
  import HorizontalSelectInputNested2 from './inputs/HorizontalSelectInputNested2';
  import LoadProfileModal from './modals/LoadProfileModal';
  import Tooltip from './Tooltip';
  import CriticalLoadProfileForm from './../critical_load_profile/CriticalLoadProfileForm';
  import NewCriticalBuildModal from './../critical_build/NewCriticalBuildModal';
  import flatPickr from 'vue-flatpickr-component';
  import 'flatpickr/dist/flatpickr.css';
  import MultipleOutagesInputs from './inputs/MultipleOutagesInputs';

  export default {
    components: {
      // nested 2 b/c run.site.load_profile - measured from run
      flatPickr,
      HorizontalStringInputNested2,
      HorizontalSelectInputNested2,
      LoadProfileModal,
      Tooltip,
      CriticalLoadProfileForm,
      NewCriticalBuildModal,
      MultipleOutagesInputs,
    },

    data: function() {
      return {
        validateOutageDateError: false
      }
    },

    computed: {
      ...mapGetters([
        'clientSideValidations',
        'criticalBuilds',
        'criticalLoadProfiles',
        'criticalLoadProfileLoading',
        'criticalPercentLoadProfile',
        'criticalUploadedLoadProfile',
        'criticalBuildLoadProfile',
        'currentUser',
        'elecLoadProfile',
        'elecSimBuildAnnualLoadProfile',
        'elecSimBuildMonthlyLoadProfile',
        'elecSimCampusLoadProfile',
        'elecUploadedLoadProfile',
        'elecLoadProfileLoading',
        'placeholders',
        'run',
        'showCriticalPercentLoadProfile',
        'showCriticalUploadedLoadProfile',
        'showCriticalBuildLoadProfile',
        'showCriticalLoadProfileForm',
        'showCriticalBuildForm',
        'urlRoot',
        'flatPickrConfig',
        'year',
      ]),
      validateOutageDateErrorMessage: function() {
        return this.clientSideValidations.run.site.load_profile.outage_start_date;
      },
      watchDependencies() {
        //elecLoadProfileLoading is watched since the getDefaultOutageDateHour function is hit before the simulated load is fully returned from the API
        return this.run.focus, 
        this.elecLoadProfileLoading,
        this.elecLoadProfile,
        this.run.site.load_profile.doe_reference_name,
        this.run.site.load_profile.annual_kwh,
        this.elecSimBuildMonthlyLoadProfile.meanKw + this.elecSimBuildMonthlyLoadProfile.minKw + this.elecSimBuildMonthlyLoadProfile.maxKw,
        this.elecSimCampusLoadProfile.meanKw + this.elecSimCampusLoadProfile.minKw + this.elecSimCampusLoadProfile.maxKw,
        this.elecUploadedLoadProfile.meanKw + this.elecUploadedLoadProfile.minKw + this.elecUploadedLoadProfile.maxKw,
        this.run.site.load_profile.critical_load_type,
        this.criticalUploadedLoadProfile,
        this.criticalBuildLoadProfile,
        this.run.site.load_profile.custom_multiple_outage_dates,
        this.run.site.load_profile.outage_duration,
        this.run.site.load_profile.number_of_outages,
        new Date();
      },
    },

    methods: {
      validateOutageDate: function() {
        if(!this.run.site.load_profile.outage_start_date) {
          this.validateOutageDateError = true;
        } else if(this.run.site.load_profile.outage_start_date) {
          this.validateOutageDateError = false;
        }
      },
      displayCriticalLPLoading: function() {
        this.$store.commit('updateObject', { object: 'criticalLoadProfileLoading', value: true });
        var self = this;
        setTimeout(function() {
          self.$store.commit('updateObject', { object: 'criticalLoadProfileLoading', value: false });
        }, 800);
      },
      updateStore: function(attr, value) {
        var payload = {
          association_1: 'site',
          association_2: 'load_profile',
          attribute: attr,
          value: value,
        }

        this.$store.commit('updateValueNested2', payload);
      },
      updateCriticalLoadType: function(event) {
        var target = event.currentTarget.href.split("#")[1];
        if(target === "percent") {
          this.updateStore('critical_load_type', 'percent');
        } else if(target === "upload") {
          this.updateStore('critical_load_type', 'upload');
        } else if(target === "custom_build") {
          this.updateStore('critical_load_type', 'custom_build');
        }
      },
      calculateCriticalPercentLP: function(typicalLoadProfile) {
        var percent = (this.run.site.load_profile.critical_load_fraction / 100.0) || (this.placeholders.site.load_profile.critical_load_fraction / 100.0);
        var self = this;

        return {
          loadsKw: self[typicalLoadProfile].loadsKw.map(function(el, i) {
            return parseFloat((el * percent).toFixed(3));
          }),
          minKw: self[typicalLoadProfile].minKw * percent,
          meanKw: self[typicalLoadProfile].meanKw * percent,
          maxKw: self[typicalLoadProfile].maxKw * percent,
        }
      },
      displayCriticalPercentLoadProfile: function(event) {
        var typicalLoadType = this.run.site.load_profile.typical_load_type;
        var criticalLoadType = this.run.site.load_profile.critical_load_type;
        var payload;
        var display;
        if(typicalLoadType == "simulated_building" && criticalLoadType == "percent" && this.run.site.load_profile.simulated_load_type == "annual" && this.elecSimBuildAnnualLoadProfile.loadsKw) {
          payload = this.calculateCriticalPercentLP("elecSimBuildAnnualLoadProfile");
          display = true;
        } else if(typicalLoadType == "simulated_building" && criticalLoadType == "percent" && this.run.site.load_profile.simulated_load_type == "monthly" && this.elecSimBuildMonthlyLoadProfile.loadsKw) {
          payload = this.calculateCriticalPercentLP("elecSimBuildMonthlyLoadProfile");
          display = true;
        } else if(typicalLoadType == "simulated_campus" && criticalLoadType == "percent" && this.elecSimCampusLoadProfile.loadsKw) {
          payload = this.calculateCriticalPercentLP("elecSimCampusLoadProfile");
          display = true;
        } else if(typicalLoadType == "uploaded" && criticalLoadType == "percent" && this.elecUploadedLoadProfile.loadsKw) {
          payload = this.calculateCriticalPercentLP("elecUploadedLoadProfile");
          display = true;
        } else {
          payload = {};
          display = false;
          bootbox.alert('A critical load profile must be selected or uploaded first.');
        }

        this.$store.commit('updateObject', { object: 'criticalPercentLoadProfile', value: payload });
        this.$store.commit('updateObject', { object: 'showCriticalPercentLoadProfile', value: display });
      },
      displayCriticalBuildLoadProfile: function() {
        if(this.run.critical_build_id) {
          this.$store.commit('updateObject', { object: 'showCriticalBuildLoadProfile', value: true });
        } else {
          bootbox.alert('A critical load profile must be selected first.');
        }
      },
      updateUploadedCriticalLoadProfile: function(values) {
        var avgValue = 0;
        var minValue;
        var maxValue;
        for(var i = 0; i < values.length; i++) {
          var value = values[i];
          avgValue += value;

          if(minValue === undefined || value < minValue) {
            minValue = value;
          }

          if(maxValue === undefined || value > maxValue) {
            maxValue = value;
          }
        }
        avgValue = avgValue / values.length;

        var payload = {
          loadsKw: values,
          minKw: minValue,
          meanKw: avgValue,
          maxKw: maxValue,
        }
        this.$store.commit('updateObject', { object: 'criticalUploadedLoadProfile', value: payload });
      },
      updateCriticalBuildLoadProfile: function() {
        this.$store.commit('updateObject', { object: 'criticalLoadProfileLoading', value: true });
        var self = this;

        if(this.run.critical_build_id) {
          $.ajax({
            url: self.urlRoot + '/fetch-critical-build-load-data',
            data: { id: self.run.critical_build_id },
            success: function handleResults(data) {
              var payload = {
                loadsKw: data.critical_loads_kw,
                minKw: Number(data.min_load),
                meanKw: Number(data.avg_load),
                maxKw: Number(data.max_load),
              }

              self.$store.commit('updateObject', { object: 'criticalBuildLoadProfile', value: payload });
            },
            error: function() {
              return false;
            }
          })
        } else {
          this.$store.commit('updateObject', { object: 'criticalBuildLoadProfile', value: {} });
        }

        setTimeout(function() {
          self.$store.commit('updateObject', { object: 'criticalLoadProfileLoading', value: false });
        }, 800);
      },
      parseCSV: function(event) {
        var file = event.target.files[0];

        if(file && file.name.split(".")[1] != "csv") {
          bootbox.alert("Uploaded file " + file.name + " must be a .csv");
          this.run.site.load_profile.critical_loads_kw = [];
          event.target.value = "";

          return false;
        }

        if(file) {
          var self = this;
          Papa.parse(file, {
            delimiter: ",",
            complete: function(results) {
              try {
                if(results.errors && results.errors.length > 0) {
                  this.run.site.load_profile.critical_loads_kw = [];
                  event.target.value = "";
                  bootbox.alert('The load profile could not be parsed. File must be a CSV.<br><br>Error (row ' + results.errors[0].row + '): ' + results.errors[0].message);

                  return false;
                }

                var rows = results.data;
                var values = [];
                var hasColumns = false;
                for(var i = 0, len = rows.length; i < len; i++) {
                  var row = rows[i];

                  // Detect header columns.
                  if(i < 3) {
                    if(row[0] && typeof row[0] === 'string') {
                      var value = row[0].toLowerCase();

                      // Detect when the CSV has multiple columns. Otherwise assume
                      // the CSV is just straight list of values.
                      if(value.indexOf('hour') !== -1) {
                        hasColumns = true;
                      }

                      // Skip header columns.
                      if(value.indexOf('hour') !== -1 || value.indexOf('directions') !== -1) {
                        continue;
                      }
                    }
                  }

                  // Skip empty rows.
                  if(row.join('') === '') {
                    continue;
                  }

                  // Ensure the values are only numbers.
                  var valueIndex = (hasColumns) ? 1 : 0;
                  var value = parseFloat(row[valueIndex]);
                  if(isNaN(value)) {
                    self.run.site.load_profile.critical_loads_kw = [];
                    event.target.value = "";
                    bootbox.alert('The load profile must contain only numeric values. Could not parse value at row ' + (i + 1) + ': "' + row[valueIndex] + '"');

                    return false;
                  }
                  values.push(value);
                }

                // Validate the file contains the expected number of value rows.
                var valuesCount = values.length;
                if(valuesCount !== 8760 && valuesCount !== 17520 && valuesCount !== 35040) {
                  self.run.site.load_profile.critical_loads_kw = [];
                  event.target.value = "";
                  bootbox.alert('The load profile must contain either 8,760 values (hourly data), 17,520 values (30-minute data), or 35,040 values (15-minute data) of numeric values.')

                  return false;
                }

                self.updateUploadedCriticalLoadProfile(values);
                self.updateStore('critical_loads_kw', JSON.stringify(values));
                self.$store.commit('updateValueNested2', {association_1: 'site', association_2: 'load_profile', attribute: 'critical_load_file_name', value: file.name});
                self.displayCriticalLPLoading();
              } finally {
              }
            }
          });
        }
      },
      displayCriticalUploadedLoadProfile: function() {
        // display electric simulated campus load profile
        if(!this.criticalUploadedLoadProfile.loadsKw) {
          bootbox.alert('Please verify that you have selected or uploaded a critical electric load profile in addition to the critical electric load input.');
          return false;
        }

        this.$store.commit('updateObject', { object: 'showCriticalUploadedLoadProfile', value: true });
      },
      getCriticalLoadProfileName: function(loadProfile) {
        return JSON.parse(loadProfile.file_data).metadata.filename.split(".")[0];
      },
      displayCriticalLoadProfileForm: function() {
        this.$store.commit('updateObject', { object: 'showCriticalLoadProfileForm', value: true });
      },
      updateCriticalLoadProfile() {
        this.$store.commit('updateObject', { object: 'criticalLoadProfileLoading', value: true });
        var id = this.run.site.load_profile.critical_load_profile_id;
        if(!id) {
          this.$store.commit('updateObject', { object: 'criticalUploadedLoadProfile', value: {} });
          this.updateStore('critical_loads_kw', []);
          this.$store.commit('updateObject', { object: 'criticalLoadProfileLoading', value: false });

          return false;
        }

        var loadProfile = this.criticalLoadProfiles.find(function(loadProfile) {
          return loadProfile.id == id;
        })
        var payload = {
          loadsKw: loadProfile.load_kw.map(function(el) {
            return parseFloat(el);
          }),
          minKw: loadProfile.min_load,
          meanKw: loadProfile.avg_load,
          maxKw: loadProfile.max_load,
        }
        this.$store.commit('updateObject', { object: 'criticalUploadedLoadProfile', value: payload });
        this.updateStore('critical_loads_kw', JSON.stringify(payload.loadsKw));

        var self = this;
        setTimeout(function() {
          self.$store.commit('updateObject', { object: 'criticalLoadProfileLoading', value: false });
        }, 800);
      },
      updateSelectedCriticalLoadProfile: function(event, payload) {
        var fileData = JSON.parse(payload.file_data);
        var fileName = fileData.metadata.filename.split(".")[0];
        var id = payload.id;

        var newLoadProfile = {
          id: id,
          file_data: payload.file_data,
          year: payload.year,
          load_kw: payload.load_kw,
          min_load: payload.min_load,
          avg_load: payload.avg_load,
          max_load: payload.max_load,
        }

        this.criticalLoadProfiles.push(newLoadProfile);
        this.updateStore('critical_load_profile_id', id);
        this.updateStore('critical_loads_kw', JSON.stringify(payload.load_kw));
        this.updateCriticalLoadProfile();
      },
      downloadLoadProfile: function(loadProfile) {
        var values = this[loadProfile].loadsKw;
        if(!values || values.length == 0) {
          bootbox.alert("A critical load profile must be selected or uploaded first.");
          return false;
        }

        var rows = [ ['Hour', 'Electric Load (kW)'] ];
        for(var i = 0, len = values.length; i < len; i++) {
          rows.push([i + 1, values[i]]);
        }

        var csv = Papa.unparse(rows);
        var blob = new Blob([csv], {
          type: 'text/csv;charset=utf-8',
        });
        saveAs(blob, 'REopt Critical Load Profile.csv');
      },
      displayCriticalBuildForm: function() {
        this.$store.commit('updateObject', { object: 'showCriticalBuildForm', value: true });
      },
      updateSelectedCriticalBuild: function(event, payload) {
        this.$store.commit('updateObject', { object: 'showCriticalBuildForm', value: false });

        var newCriticalBuild = { id: payload.id, name: payload.name };
        var criticalBuilds = this.criticalBuilds;
        criticalBuilds.push(newCriticalBuild);
        this.$store.commit('updateObject', { object: 'criticalBuilds', value: criticalBuilds });

        this.$store.commit('updateValue', { attribute: 'critical_build_id', value: payload.id });
        this.updateCriticalBuildLoadProfile();
      },
      displayCriticalLoadWithOutageButton: function(event) {
        this.$store.commit('updateObject', { object: 'showStartOutageOnPeakButton', value: true });
        var type = this.run.site.load_profile.critical_load_type;

        if(type == "percent") {
          this.displayCriticalPercentLoadProfile();
        } else if(type == "upload") {
          if(this.criticalUploadedLoadProfile && Object.keys(this.criticalUploadedLoadProfile).length > 0) {
            this.$store.commit('updateObject', { object: 'showCriticalUploadedLoadProfile', value: true });
          } else {
            bootbox.alert("A critical load profile must be selected or uploaded first.");
          }
        } else if(type == "custom_build" && Object.keys(this.criticalBuildLoadProfile).length > 0) {
          if(this.criticalBuildLoadProfile) {
            this.$store.commit('updateObject', { object: 'showCriticalBuildLoadProfile', value: true });
          } else {
            bootbox.alert("A critical load profile must be selected or uploaded first.");
          }
        }

        event.preventDefault();
      },
      startOutageOnPeak: function(event, maxDate) {
        this.updateStore('outage_start_date', maxDate.toISOString().split('T')[0]);
        this.updateStore('outage_start_hour', maxDate.getHours());

        // not sure why the binding from above line is not working :/ but below is needed to update select input
        document.getElementById("run_site_attributes_load_profile_attributes_outage_start_hour").value = maxDate.getHours();
      },
      getDefaultOutageDateHour: function() {
        if(!this.run.site.load_profile.outage_duration || this.run.site.load_profile.custom_multiple_outage_dates || this.run.site.load_profile.number_of_outages == 1) {
          return false;
        } else if ((this.elecSimBuildAnnualLoadProfile.loadsKw && this.elecSimBuildAnnualLoadProfile.loadsKw.length == 0) && (this.elecSimBuildMonthlyLoadProfile.loadsKw && this.elecSimBuildMonthlyLoadProfile.loadsKw.length == 0) && (this.elecSimCampusLoadProfile.loadsKw && this.elecSimCampusLoadProfile.loadsKw.length == 0) && (this.elecUploadedLoadProfile.loadsKw && this.elecUploadedLoadProfile.loadsKw.length == 0) && this.run.site.load_profile.critical_loads_kw.length == 0) { 
          return false;
        }
        var self = this;
        var params = {
          seasonal_peaks: 1,
          outage_duration: parseInt(this.run.site.load_profile.outage_duration),
          start_not_center_on_peaks: 0,
        }
        var typicalLoadType = this.run.site.load_profile.typical_load_type;
        var criticalLoadType = this.run.site.load_profile.critical_load_type;
        if(typicalLoadType == "simulated_building" && criticalLoadType == "percent" && this.run.site.load_profile.simulated_load_type == "annual" && this.elecSimBuildAnnualLoadProfile.loadsKw) {
          params['critical_load'] = this.calculateCriticalPercentLP("elecSimBuildAnnualLoadProfile")['loadsKw'];
        } else if(typicalLoadType == "simulated_building" && criticalLoadType == "percent" && this.run.site.load_profile.simulated_load_type == "monthly" && this.elecSimBuildMonthlyLoadProfile.loadsKw) {
          params['critical_load'] = this.calculateCriticalPercentLP("elecSimBuildMonthlyLoadProfile")['loadsKw'];
        } else if(typicalLoadType == "simulated_campus" && criticalLoadType == "percent" && this.elecSimCampusLoadProfile.loadsKw) {
          params['critical_load'] = this.calculateCriticalPercentLP("elecSimCampusLoadProfile")['loadsKw'];
        } else if(typicalLoadType == "uploaded" && criticalLoadType == "percent" && this.elecUploadedLoadProfile.loadsKw) {
          params['critical_load'] = this.calculateCriticalPercentLP("elecUploadedLoadProfile")['loadsKw'];
        } else if(criticalLoadType == "upload") {
          params['critical_load'] = this.run.site.load_profile.critical_loads_kw;
        } else if (criticalLoadType == "custom_build") {
          params['critical_load'] = this.criticalBuildLoadProfile.loadsKw
        }

        $.ajax({
          url: self.urlRoot + "/peak-load-outage-times",
          method: 'POST',
          data: JSON.stringify(params),
          contentType: "application/json",
          processData: false,
          success: function handleResults(data) {
            var year = (self.run.site.load_profile.year) ? Number(self.run.site.load_profile.year) : Number(self.year);
            var outagedate = new Date(Date.UTC(year, 0, 1));
            for(var i=0; i < 4; i++){
              var outagedate = new Date(Date.UTC(year, 0, 1));          
              outagedate.setUTCSeconds(Math.floor(data.outage_start_time_steps[i]/24) * 24 * 3600);
              var payload = {
                index: i,
                outage_duration: Number(self.run.site.load_profile.outage_duration),
                outage_start_date: `${year}-${outagedate.getUTCMonth()+1}-${outagedate.getUTCDate()}`,
                outage_start_hour: data.outage_start_time_steps[i]%24,
                outage_start_time_step: data.outage_start_time_steps[i],
              }
              self.$store.commit('updateOutageDateHour', payload);
            }
          },
          error: function(data) {
            return false;
          },
        })
      },
      resetInputs: function() {
        if(confirm("Are you sure you want to reset the inputs in the Resilience section to their default values?")) {
          this.updateStore('outage_duration', '');
          this.updateStore('number_of_outages', 4);
          this.updateStore('custom_multiple_outage_dates', false);
          this.updateStore('outage_start_date', '');
          this.updateStore('outage_start_hour', '');
          this.updateStore('critical_load_fraction', '');
          this.updateStore('critical_load_file_name', '');
          document.querySelectorAll("[href='#percent']")[0].click();
        }
      }
    },

    watch: {
      'validateOutageDateError': function() {
        if(this.validateOutageDateError) {
          document.getElementsByClassName('run_site_load_profile_outage_start_date')[0].classList.add('has-error');
        } else {
          document.getElementsByClassName('run_site_load_profile_outage_start_date')[0].classList.remove('has-error');
        }
      },
      watchDependencies: function() {
        if(this.run.site.load_profile.outage_duration) {
          if(!this.run.site.load_profile.custom_multiple_outage_dates) {
            this.getDefaultOutageDateHour();
          } 
        }
        if (this.run.site.load_profile.number_of_outages == 1 && this.run.site.load_profile.custom_multiple_outage_dates) {
          // reset custom outage dates if single outage selected after custom checkbox selected
          // so it doesn't show up on the results page inputs accordion
          this.run.site.load_profile.custom_multiple_outage_dates = false;
        }
      },
    },

    mounted: function() {
      //TODO: the .click() should not ne necessary once bootstrap-vue integrated
      if(this.run.site.load_profile.critical_load_type == "percent") {
        document.querySelectorAll("[href='#percent']")[0].click();
      } else if(this.run.site.load_profile.critical_load_type == "upload") {
        document.querySelectorAll("[href='#upload']")[0].click();

        if(this.currentUser) this.updateCriticalLoadProfile();
        // this allows the "Chart critical load data" to work on copy
        var critical_loads_kw = JSON.parse(this.run.site.load_profile.critical_loads_kw);
        this.updateUploadedCriticalLoadProfile(critical_loads_kw);
      } else if(this.run.site.load_profile.critical_load_type == "custom_build") {
        document.querySelectorAll("[href='#custom_build']")[0].click();
        // this allows the "Chart critical load data" to work on copy
        this.updateCriticalBuildLoadProfile();
      }

      // for copied runs
      if(this.run.site.load_profile.outage_duration) {
        if(!this.run.site.load_profile.custom_multiple_outage_dates) {
          this.getDefaultOutageDateHour();
        }
      }

      if(this.run.site.load_profile.critical_load_file_data) {
        document.getElementById("run_site_attributes_load_profile_attributes_critical_load_file").value = this.run.site.load_profile.critical_load_file_data;

        var loadKw = JSON.parse(this.run.site.load_profile.critical_loads_kw)
        var payload = {
          loadsKw: loadKw,
          minKw: Math.min.apply(Math, loadKw),
          meanKw: this.$store.getters.average(loadKw),
          maxKw: Math.max.apply(Math, loadKw),
        }

        this.$store.commit("updateObject", { object: "criticalUploadedLoadProfile", value: payload });
      }
    }
  }
</script>
